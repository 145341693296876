import React from 'react'

import './Banner.scss'
import Image from 'gatsby-image'

const Banner = ({ image, title }) => {
  return (
    <div className="banner">
      <Image
        className="py-5 py-lg-0"
        fluid={image.localFile.childImageSharp.fluid}
      />

      {title && (
        <div className="empty-space-50 color-background-main">
          <div className="container h-100 d-flex align-items-center">
            <div className="row">
              <div className="col">
                <strong className="font-weight-xl">{title}</strong>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Banner