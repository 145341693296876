export const encodeQueryData = (data) => {
  const ret = []
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  return ret.join('&')
}

export const chunkArray = (array, size) => {
  const chunkedArray = []
  const copied = [...array] // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / size) // Round up to the nearest integer

  for (let i = 0; i < numOfChild; i += 1) {
    chunkedArray.push(copied.splice(0, size))
  }

  return chunkedArray
}

export const calculateColumn = (length) => {
  return 12 / length
}
