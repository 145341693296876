/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { v4 } from 'uuid'
// Components
import Content from 'components/Content'
// CSS
import './GravityForm.scss'
// Third Party
import Select from 'react-select'
// Utilities
import { calculateColumn, chunkArray } from '../../utils'
import { set } from 'lodash'


const GravityForm = ({ id, perColumn }) => {
  const { allGfForm: { edges: gravityData } } = useStaticQuery(graphql`
		query {
			allGfForm {
				edges {
					node {
						formId
						slug
						apiURL
						descriptionPlacement
						formFields {
							id
							label
							labelPlacement
							description
							descriptionPlacement
							type
							choices
							content
							errorMessage
							inputMaskValue
              conditionalLogic
							isRequired
							visibility
							cssClass
							placeholder
							size
							defaultValue
							maxLength
						}
						button {
							text
						}
						confirmations {
							message
						}
					}
				}
			}
		}
  `)

  const { node: gfForm } = gravityData.filter(({ node }) => node.formId === id)[0]

  const [fields, setFields] = useState({})
  const [form, setForm] = useState(gfForm)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')
  const [showFields, setShowFields] = useState()

  // check after two seconds if field input_17 in fields has value then set the ShowFields to true otherwise false
  setTimeout(() => {
    if (fields.input_17_2 === 'Nee ik kan niet') {
      setShowFields(true)
    } else {
      setShowFields(false)
    }
  }, 1000)

  useEffect(() => {
    let tempForm = form

    // add submit button as a field
    if (tempForm.formFields.filter((item) => item.type === 'submit').length === 0) {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: v4(),
          type: 'submit',
          text: tempForm.button.text,
        },
      ]
    }

    if (perColumn) {
      tempForm = {
        ...tempForm,
        formFields: chunkArray(tempForm.formFields, perColumn),
      }
    }


    setForm({
      ...form,
      ...tempForm,
    })
  }, [])


  async function handleOnSubmit(event) {
    event.preventDefault()

    if (status === 'processing') {
      return
    }

    setStatus('processing')

    try {
      const formData = new FormData()

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value)
      }

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData,
      })

      const response = await request.json()
      if (response.is_valid === true) {
        setStatus('done')

        setMessage(response.confirmation_message)
      } else {
        setStatus('error')
        console.log(response)
      }
    } catch (error) {
      setStatus('error')
      console.error(error)
    }
  }

  function handleFieldChange(event) {
    // eslint-disable-next-line prefer-destructuring

    let { value } = event.target || event.value

    if (event.target) {
      if (event.target.type === 'checkbox') {
        value = event.target.checked ? event.target.value : ''
      }

      setFields({
        ...fields,
        [event.target.name]: value,
      })
    } else {
      setFields({
        ...fields,
        [event.name]: event.value,
      })
    }
  }

  if (status === 'done') {
    return <Content className="form-submission-message" content={message} />
  }

  if (form.formFields) {
    return (
      <form method="post" className={`form form_${form.formId}`} onSubmit={handleOnSubmit}>
        {status === 'processing' && (
          <Loading />
        )}
        <div className="row">
          {form.formFields && form.formFields.map((field, key) => {



            if (Array.isArray(field)) {
              return (
                <div key={key} className={`col-lg-${calculateColumn(form.formFields.length)}`}>
                  {field.map((item, index) => (
                    <FormField key={index} showFields={showFields} field={item} fields={fields} onChange={handleFieldChange} />
                  ))}
                </div>
              )
            }

            return (
              <div className="col-lg-12">
                <FormField key={key} showFields={showFields} field={field} fields={fields} onChange={handleFieldChange} />
              </div>
            )
          })}
        </div>
      </form>
    )
  }

  console.error('No gravity forms found with id', id)
  return false
}

const TextField = ({ value, onChange, showFields, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass, conditionalLogic } }) => {

  const logic = JSON.parse(conditionalLogic)

  if (logic.enabled === true && showFields === false) {
    return false
  }
  return (
    <div className="form-group">
      {labelPlacement !== 'hidden_label' && <label htmlFor={`input_${id}`}>{label}</label>}
      <input
        value={value}
        onChange={onChange}
        type="text"
        id={`input_${id}`}
        className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
        name={`input_${id}`}
        required={isRequired}
        placeholder={placeholder}
      />
    </div>
  )
}

const EmailField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && <label htmlFor={`input_${id}`}>{label}</label>}
    <input
      value={value}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const PhoneField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && <label htmlFor={`input_${id}`}>{label}</label>}
    <input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const TextAreaField = ({ value, onChange, showFields, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass, conditionalLogic } }) => {

  const logic = JSON.parse(conditionalLogic)

  if (logic.enabled === true && showFields === false) {
    return false
  }

  return (
    <div className="form-group">
      {labelPlacement !== 'hidden_label' && <label htmlFor={`input_${id}`}>{label}</label>}
      <textarea
        value={value}
        onChange={onChange}
        id={`input_${id}`}
        className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
        name={`input_${id}`}
        required={isRequired}
        placeholder={placeholder}
      />
    </div>
  )
}

const CheckboxField = ({ value, onChange, field: { id, type, cssClass, choices } }) => {
  const list = JSON.parse(choices)

  return (
    <div className="form-group">
      {list.map((checkbox, key) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label htmlFor={`input_${id}_${key + 1}`} className="checkbox-content">{checkbox.text}</label>
        </div>
      ))}
    </div>
  )
}

// Select or Dropdown
const SelectField = ({ field, onChange, value }) => {

  // Populate a options array
  const options = []
  JSON.parse(field.choices).forEach(choice => {
    options.push({
      value: choice.value,
      label: choice.text,
      type: 'select',
      name: `input_${field.id}`,
    })
  })

  // Custom Select component
  const MySelect = props => (
    <Select
      {...props}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  )

  return (
    <>
      {field.labelPlacement !== 'hidden_label' && <label htmlFor={`input_${field.id}`}>{field.label}</label>}
      <div className="form-select">
        <MySelect
          options={options}
          onChange={onChange}
          value={options.filter(option => option.value === value)}
          placeholder={field.label}
          classNamePrefix="react-select"
          className="react-select-container"
        />
      </div>
    </>
  )
}

const SubmitButton = ({ field }) => (
  <div className="form-footer">
    <button className="button button-alt form-submit" type="submit">
      {field.text}
    </button>
  </div>
)

export const FormField = ({ field, fields, onChange, showFields }) => (
  <>
    {field.type === 'text' && <TextField onChange={onChange} showFields={showFields} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === 'email' && <EmailField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === 'phone' && <PhoneField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === 'textarea' &&
      <TextAreaField onChange={onChange} showFields={showFields} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === 'checkbox' &&
      <CheckboxField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === 'select' && <SelectField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === 'submit' && <SubmitButton field={field} />}
  </>
)

export const Loading = () => (
  <div className="form-loading">
    <div className="form-loading-spinner">
      <div className="sk-chase">
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
      </div>
    </div>
  </div>
)

export default GravityForm


